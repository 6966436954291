
import "./ContactFormJoinUs.css";
import React, { useState } from 'react';
import axios from 'axios';
import Loader from "./Loader/Loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./ProductEnquiryForm.css";
function ContactForm(props) {
    const [name, setName] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);
    const handleChangeName = (e) => {
        setName(e.target.value)
    }
    const handleChangeCompanyname = (e) => {
        setCompanyname(e.target.value)
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleChangeMessage = (e) => {
        setMessage(e.target.value)
    }
    const clearState = () => {
        setName("")
        setCompanyname("")
        setEmail("")
        setMessage("")

    }
    function isValidEmail(email) {
        // Define a regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const handleSubmit = (e) => {

        if (name && message && isValidEmail(email)) {
            setLoader(true)
            let submitObj = {
                name: name,
                companyname: companyname,
                email: email,
                message: message,
            }
            // fetch("https://sendemail.dezinin.com/sendmail_contact", {
              // fetch("http://localhost:5000/sendmail_contact", {

              fetch("https://send-email-one.vercel.app/sendmail_contact", {


              // Adding method type
              method: "POST",

              // Adding body or contents to send
              body: JSON.stringify({
                name: name,
                companyname: companyname,
                email: email,
                message: message,
              }),

              // Adding headers to the request
              headers: {
                  "Content-type": "application/json; charset=UTF-8"
              }
          }).then((res) => {
                toast("Successfully send your mail")
                setLoader(false)
                clearState()

            }).catch((err) => {
                console.log("response err", err)
                setLoader(false)
                toast("Something wrong")
            });
        } else {
            toast("Please input all mandatory field")
            setLoader(false)
        }

    }

  return (
    <div className="contact-form-container join_us_contact_form">
      <h2>CONTACT US</h2>
      <p>
        Please use the form below to send us your query. Our team will respond
        within 24 hours.
      </p>
      <div className="contact-form">
        <div className="form-row">
          <div className="form-group">
            <label>Name: <span className="required">*</span></label>
            <input onChange={(e) => handleChangeName(e)} value={name} type="text" id="name" name="name"  placeholder="Enter your name" required/>
          </div>
          <div className="form-group">
            <label>Company Name:</label>
            <input onChange={(e) => handleChangeCompanyname(e)} value={companyname} type="text" id="companyname" name="companyname" placeholder="Enter your company name" />
          </div>
          <div className="form-group">
            <label>Email Address: <span className="required">*</span></label>
            <input  onChange={(e) => handleChangeEmail(e)} value={email} type="email" id="email" name="email"  placeholder="Enter your email address" required/>
          </div>
        </div>
        <div className="form-group contact_form_join_us">
          <label>Message: <span className="required">*</span></label>
          <textarea onChange={(e) => handleChangeMessage(e)} value={message} id="message" name="message" placeholder="Enter your message" rows="5" required></textarea>
        </div>
        <div className='submit_btn_diiv '>
        <button className="submit-btn enquiry_submit_btn" onClick={(e) => handleSubmit(e)} >Submit Enquiry</button>
        {loader ? <Loader /> : ""}
        </div>
      </div>
      {/* <p className="note">(*) indicates mandatory fields</p> */}
    </div>
  );
}

export default ContactForm;
