import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import imageOne from "../../assets/images/MAKE A BETTER SUSTAINABLE FUTURE.jpg";
import Fade from "react-reveal";
import LazyLoad from "react-lazyload";

export class Trend extends Component {
  render() {
    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));
    return (
      <section id="collection" className="sustainability_main">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <Grid container spacing={0} className="full_part_div no_two_part_for_mobile_view">
              <Grid item xs={6} md={6} className="grid_image_part">
              <LazyLoad height={200} offset={100}>
                <img src={imageOne} width="600" height="600" alt="no_img" />
              </LazyLoad>
              </Grid>

              <Grid item xs={6} md={6} className="half_part_div_sustainability ">
                <div className="half_part_div_second sustainability_part">

                  <div>
                    <h2 className="header_p header_text_size">Make A Better Sustainable Future</h2>
                  </div>
                  <div>
                    <p className="make_a_para">
                      We excel in creating distinctive designs for household brands,
                      meticulously forecasted and crafted with organic
                      and sustainable materials. Elevate your brand
                      with our commitment to uniqueness and eco-conscious solutions.
                    </p>
                  </div>
                  {/* <div>
                    <button className="explore_all_button sustainablity">
                      Explore all ->
                    </button>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Trend;
